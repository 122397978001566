.callout {
    margin: 0 calc(0px - var(--spacing));
    h2 {
        margin: 0;
    }
    .wp-block-button:only-child a:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 6;
    }

    .wp-block-button .wp-block-button__link,
    .wp-block-button .wp-block-button__link:visited {
        position: initial;
    }
}
