.wp-block-mu-accordion-selector{
	padding: 10px 20px;
	border: 1px solid #D8D8D8;
	float: right;

	+ * {
		clear: right;
	}

	button{
		border: none;
		background: none;
		font-size: 0.875rem;
		color: var(--text-highlight);
		margin: 0;
		padding:0;
		cursor: pointer;
		
		&:hover{
			text-decoration: underline;
		}
		
		&:first-of-type{
			margin-right: 10px;
			padding-right: 10px;
			border-right: 1px solid #D8D8D8;
		}
		&.activated {
			font-weight: bold;
		}
	}

}

.wp-block-mu-accordion{
	position: relative;
	margin: 0;
	padding: calc( 2 * var(--spacing) ) 0;
	padding-left: calc( 2 * var(--spacing) );

	+ .wp-block-mu-accordion{
		border-top: 1px solid #D8D8D8;
	}
	details{
		&[open] summary > * {
			font-weight: bold;
			
		}
	}
	details[open] summary:before {
		transform: rotate(0deg);
	}
	summary{
		&::-webkit-details-marker {
			display:none;
		}
		h2, h3, h4, h5, h6, span {
			font-size: 1.25rem;
			color: #000;
			font-family: var(--sansserif);
			margin: 0;
			padding: 0;
			line-height: 1.875rem;
		}
		
		list-style: none;
		cursor: pointer;
		
		&:before{
			content: '';
			display: block;
			height: 14px;
			width: 14px;
			position: absolute;
			left: 0;
			margin-top: 4px;
			transition: transform 0.25s ease;
			transform: rotate(135deg);
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.5 13.5' style='stroke-width: 3;stroke:%230D64B2;'%3E%3Cg%3E%3Cpath d='M13.5 0 0 13.5'%3E%3C/path%3E%3Cpath d='M13.5 13.5 0 0'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");	
		}
	}
}