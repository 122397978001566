.top-news-and-event {
	.image {
		@media (min-width: 1024px) {
			position: relative;
			flex: none;
			width: 50%;
		}

		img {
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			@media (min-width: 1024px) {
				width: 100%;
				height: 100%;
				max-height: 100%;
				position: absolute;
			}
		}
	}

	.details {
		padding: var(--spacing);
		display: flex;
		justify-content: center;
		flex-direction: column;
		@media (min-width:1024px) {
			order: 1;
			padding: 50px;
		}

		.title {
			line-height: 1.3;
			margin-top: 0;
			font-size: clamp(1.5rem, 0.6964285714285715rem + 4.017857142857142vw, 2.625rem);
			@media (min-width: 1024px) {
				font-size: clamp(1.9rem, -0.075rem + 3.06vw, 2.625rem);
			}
			a {
				text-decoration: underline;
				text-decoration-thickness: 0.5px;
				text-underline-offset: 5px;
				text-decoration-skip-ink: all;
				text-decoration-color: var(--link-underline);
				transition: all 0.15s;
				background: none;
				padding: 0;
				position: static;

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
				}

				&:focus,
				&:hover {
					color: var(--text-highlight);
					text-decoration-color: var(--text-highlight);
					text-decoration-thickness: 2px;
				}

			}
		}
	}

	.wp-block-button__link {
		margin-top: 1.5rem;
		&:not(.event-link) {
			position: relative;
			z-index: 2;
			margin-top: 1rem;
			padding-bottom: 1rem;
		}
		&:focus-visible,
		&:focus,
		&:hover {
			color: var(--text-color);
			text-decoration: underline;
		}

		&:active {
			color: var(--text-color);
			text-decoration: underline;
		}
		&.event-link{
			position: static;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}
	}
	.top-news-story,
	.top-event {
		padding: 0;
		display: flex;
		flex-direction: column;
		position: relative;
		@media (min-width: 1024px) {
			flex-direction: row;
		}
	}	

	.top-news-story {
		--text-highlight: #fff;
		--link-underline: #fff7;
		.image {		
			@media (min-width:1024px) {
				order: 2;
			}
		}
		.wp-block-button__link:hover,
		.wp-block-button__link:focus {
			color: #fff;
		}
	}
	.top-event {
		--text-highlight: #002855;
		--link-underline: #00285577;
	}
}
