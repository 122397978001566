// @use "../content.scss";

.news {
	max-width: 1400px;
	margin: 0 auto;
	position: relative;

	display: grid;
	grid-template-columns: 1fr;

	@media (min-width: 1024px) {
		body .content & {
			margin-right: calc(0px - var(--columnWidth) - var(--gutterWidth)) !important;
			margin-left: calc(0px - var(--columnWidth) - var(--gutterWidth)) !important;
		}
	}

	.component-title {
		grid-row: 1;
		grid-column: 1 / 3;
		z-index: -1;
	}

	.news-stories-wrapper {
		margin: 0 auto;
		gap: 1em;

		grid-row: 2;
		grid-column: 1 / 3;

		@media (max-width: 1023px) {
			height: 400px;
			scroll-snap-type: x mandatory;
			display: flex;
			-webkit-overflow-scrolling: touch;
			overflow-x: scroll;
			overflow-y: hidden;
			margin: 0 calc(0px - var(--spacing));
		}

		@media (min-width: 1024px) {
			display: grid;
			width: 100%;
			aspect-ratio: 2/1;
			grid-template-areas:
				"primary secondary"
				"primary tertiary";
		}
	}

	&-story {
		position: relative;
		scroll-snap-align: center;
		background: #002855;

		@media (max-width: 1023px) {
			width: calc(Min(320px, 85vw));
			// margin: 0 var(--spacing);
			flex-shrink: 0;
		}
		&:first-of-type {
			grid-area: primary;
		}

		// &.has-no-image-1 {
		// 	background: #002855;
		// }

		&.has-no-image-2 {
			background: #0d64b2;
		}

		a:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&-image {
		width: 100%;
		height: 100%;
		position: absolute;

		// &:before {
		// 	.has-image & {
		// 		content: "";
		// 		position: absolute;
		// 		bottom: 0;
		// 		left: 0;
		// 		right: 0;
		// 		height: 100%;
		// 		z-index: 1;
		// 		background: transparent linear-gradient(0deg, #12060679 0%, #80808000 100%) 0% 0% no-repeat padding-box;
		// 	}
		// }
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	&-details {
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: end;
		padding: calc(.5 * var(--spacing));
		color: #fff;
		z-index: 2;
	}		
	.news-story.has-image .news-details:before{
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: calc(100% + 4em);
		z-index: 1;
		z-index: -1;
		background: rgb(0, 0, 0);
		mask-image: linear-gradient(
			180deg,
			rgba(0,0,0,0) 0,
			rgba(0,0,0,.1) 10%,
			rgba(0,0,0,.7) 6.5em,
			rgba(0,0,0,.9) 100%
		);
		-webkit-mask-image: linear-gradient(
			180deg,
			rgba(0,0,0,0) 0,
			rgba(0,0,0,.1) 10%,
			rgba(0,0,0,.7) 6.5em,
			rgba(0,0,0,.9) 100%
		);
	}

	.news-story h2,
	&-title {
		font-size: 1.625rem;
		font-family: var(--serif);
		font-weight: 400;
		letter-spacing: 0.78px;
		color: var(--header-color);
		
		margin: 0;
		font-style: italic;
		line-height: 1.1;
		a {
			color: #fff;
			text-decoration: none;
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&-title + p:not(.news-date) {
		margin: 0.5em 0 0 0;
	}

	.wp-block-post-date,
	&-date {
		margin: 0;
		
	}

	.news-all {
		margin-top: var(--spacing);

		@media (min-width: 1024px) {
			margin: 0;
			grid-row: 1;
			grid-column: 2;
			padding-right: var(--spacing);
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}
