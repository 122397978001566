.alert-preview {
	background: #d80000;
	color: #fff;
	min-height: 32px;

	@media(max-width: 1023px){
		position: sticky;
		top: 0;
		z-index: 10;
	}


	.wrapper-inner {
		display: flex;
		gap: 0.5em;
		padding: 0.25em 0;
	}

	p {
		margin: 0;
	}

	a {
		// display: inline-block;
		// white-space: nowrap;
		// overflow-x: hidden;
		// text-overflow: ellipsis;
		max-width: 60em;

		color: #fff;
		&:focus,
		&:hover {
		}
	}

	.alert-preview-header {
		text-transform: uppercase;
		font-weight: bold;
		display: inline-block;
	}

}

// the alert dialog
.alert {
	/*
    Forked from https: //keithjgrant.com/posts/2018/meet-the-new-dialog-element/
    */
	padding: 0;
	border: 0;

	/* The following styles are for older browsers when using
	the polyfill. These aren’t necessary for Chrome/Firefox. */
	display: none;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	/* should center it, but not working in Safari */
	max-width: 90vw;
	width: 100%;
	max-height: 85vh;
	background-color: white;

	overflow-y: auto;

	padding: 3em var(--spacing) var(--spacing);
	z-index: 100;

	box-shadow: 0px 2.6px 2.2px rgba(0, 0, 0, 0.046), 0px 6.3px 5.3px rgba(0, 0, 0, 0.065),
		0px 11.8px 10px rgba(0, 0, 0, 0.08), 0px 21px 17.9px rgba(0, 0, 0, 0.096),
		0px 39.3px 33.4px rgba(0, 0, 0, 0.117), 0px 94px 80px rgba(0, 0, 0, 0.17);

	@media (min-width: 768px) {
		max-width: 700px;
	}

	/* native backdrop */
	&::backdrop {
		background-color: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(20px);
	}

	/* polyfill backdrop */
	+ .backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(20px);
		z-index: 11;
	}

	&[open] {
		display: block;
		animation: slide-up 0.4s ease-out;

		at-root {
			@keyframes slide-up {
				0% {
					opacity: 0;
					transform: translate(-50%, calc(-50% - 15px));
				}

				100% {
					opacity: 1;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	&:before {
		left: 0;
		right: 0;
		top: 0;
		content: "";
		display: block;
		position: absolute;
		height: 2em;
		background: #d80000;
	}

	&:not([open]) {
		display: none;
	}

	&-close {
		position: absolute;
		top: 0.2em;
		right: 0.2em;
		padding: 0.3em;
		background-color: transparent;
		border: 0;
		cursor: pointer;
		color: #fff;

		span {
			position: absolute;
			left: -10000px;
		}

		&:after {
			content: "X";
		}
	}

	& > div {
		padding: 2em 0 1em;
		& + div {
			border-top: 1px solid #ccc;
		}
	}

	// headings
	header {
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}

	p {
		/* These are technically the same, but use both */
		&.alert-url {
			overflow-wrap: break-word;
			word-wrap: break-word;
			/* This is the dangerous one in WebKit, as it breaks things wherever */

			word-break: break-all;
			/* Instead use this non-standard one: */

			word-break: break-word;
			/* Adds a hyphen where the word breaks, if supported (No Blink) */

			hyphens: auto;
		}
	}
}
