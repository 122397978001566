

// add the external icon to non primary site urls, where is used to lower the specificity
a:where(
		[href*="//"]
		:not([href*="www.monmouth."])
		:not([href*="library.monmouth."])
		:not([href*="weblive.monmouth"])
		:not([href*="new.monmouth"])
		:not([href*="localhost"])
	):after {
		content: "";
		background-color: currentColor;
		-webkit-mask-image: var(--external-link-icon);
		mask-image: var(--external-link-icon);
		width: .8em;
		height: .8em;
		display: inline-block;
		margin-left: 0.5em;
  }
  

@media print{
	.simpletoc-title,
	.simpletoc-list{
		display: none;
	}

	a[href^="#"]{
		color: var(--text-color);
		text-decoration: none;
	}
	a:not([href^="tel:"]):not([href^="#"]):after {
		content: " (" attr(href) ")" ;
	}
	
	h1, h2, h3, h4, h5, h6, p{
		break-inside: avoid;
	}
}


@import 'resources-non-critical';
@import 'eyebrow-non-critical';
@import 'breadcrumbs';
@import 'footer';

@import "card";

@import 'blocks';

@import 'videos';

@import 'seriously-simple-podcasting';
@import 'gravity-forms';
@import 'tabs';

@import 'tribe-events';

// @import 'debug';
