.wp-block-media-text.alignwide.more-info-card{
	position: relative;

	&:focus-within img,
	&:hover img{
		scale: 1.05;			
	}

	figure{
		height: 240px;
		overflow: hidden;
		img{
			transition: scale 0.25s ease;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.wp-block-media-text__content{
		padding-left: 0;
		padding-right: 0;
	}


	.wp-block-button .wp-block-button__link{
		position: revert;
	}

	a:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}