.related {
	border: 1px solid var(--header-color);
	padding: 0;
	text-align: center;
	padding-bottom: var(--spacing);	

	.wp-block-image {
		margin: 0;
		img {
			width: 100%;
			height: auto;
		}
	}

	.component-title {
		text-decoration: underline;
		text-underline-offset: 15px;
		margin-bottom: 2em;
	}

	svg {
		width: 32px;
		height: auto;
	}

	.icons,
	.wp-block-buttons {
		display: block;
		text-align: center;
		width: auto;
		margin: 0 auto;
		max-width: 1024px;
		gap: 0;
		padding: 0 var(--spacing);
		@media (min-width: 768px) {
			columns: 2;
		}
		@media (min-width: 1024px) {
			padding: 0;
			columns: revert;
			display: flex;
			flex-wrap: wrap;
			justify-items: left;
			align-items: revert;
		}
		svg {
			fill: var(--text-highlight);
		}
	}
	.wp-block-button {
		display: block;
		margin: 0 0 2em 0;
		flex-shrink: 1;
		flex-basis: 25%;		
	}

	.icon-and-button{
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1em;
		position: relative;

		margin: 0 0 2em 0;
		flex-shrink: 1;
		flex-basis: 25%;		


		@media (min-width: 1024px){
			flex-direction: column;
		}

		.wp-block-button{
			margin: 0;
		}

		a{
			position: unset;
			&:after{
				content:"";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}
	}

	.icon{
		width: 45px;
		height: auto;

		@media(min-width: 768px){
			width: auto;
			height: 60px;
		}
		
		svg{
			width: 100%;
			height: auto;
			@media(min-width: 768px){
				width: auto;
				height: 100%;
			}
		}
	}
}
