ul[role="tablist"] {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1em;
	li {
		display: block;
		padding: 0;

		@media (max-width: 767px){
			flex-basis: 45%;
			flex-grow: 1;
			flex-shrink: 0;
			margin: 0;
		}

		a,
		a:visited {
			border: 1.5px solid #fff;
			text-decoration: none;
			background: #fff;
			box-shadow: 2px 2px 4px #e1e1e1;
			padding: 1rem 40px;
			font-size: 0.8125rem;
			font-family: var(--sansserif);
			font-weight: bold;
			line-height: 1.8;
			letter-spacing: 0.65px;
			text-align: center;

			--outline-color: var(--text-highlight);
			@media (max-width: 767px){
				width: 100%;
				display: inline-block;
			}
		}
		a:focus-visible,
		a:focus,
		a:hover {
			border-color: #0d46b2;
		}
		
		a[aria-selected="true"] {
			color: #fff;
			background: var(--text-highlight);
			border-color: var(--text-highlight);
			cursor: default;
		}		
	}
}