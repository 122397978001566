.resources-holder {
	position: relative;
	padding: 0 var(--spacing);
	max-width: calc(1400px + (2 * var(--spacing)));
	margin: 0 auto;
	width: 100%;
}

.resources {
	display: block; // hidden in critical
	visibility: hidden;

	width: 100%;
	max-width: 425px;
	flex-shrink: 0;
	box-shadow: 0px 2px 4px #00000029;

	font-size: 0.75rem;
	background: var(--background);
	color: var(--text-color);
	z-index: 101;
	border-top: 1px solid #fff;

	--spacing: 24px;

	@media (max-width: 1023px) {
		position: fixed;
		top: 90px;
		right: 0;
		transform: translate(100%, 0);
		bottom: 0;
	}

	@media (min-width: 1024px) {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 380px;
		box-shadow: 0px 2px 4px #00000029;
	}

	&-body{
		@media (max-width: 1023px) {
			width: 100%;

			position: absolute;
			top: 0;
			bottom: 0;

			background: var(--background);

			opacity: 0;
			will-change: auto;
			transform: translate(0, 0);
			transition: opacity 0.25s var(--easing), transform 0.25s var(--easing);

			overflow: auto;
			overscroll-behavior: contain;

			display: flex;
			flex-direction: column;

		}
		@media (prefers-reduced-motion) {
			transition: none;
		}		
	}

	header,
	a {
		-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
		page-break-inside: avoid; /* Firefox */

		display: block;

		font-weight: bold;

		padding: 1.25em 0;
		margin: 0 var(--spacing);

		color: currentColor;
		text-transform: uppercase;

		border-bottom: 1px solid #ffffff1a;
	}
	a {
		white-space: nowrap;
		text-decoration: none;

		&:last-of-type {
			border-bottom: none;
		}

		&:focus,
		&:hover {
			text-decoration: underline;
			span {
				text-decoration: none;
			}
		}

		span {
			font-size: 0.625rem;
			font-weight: normal;
			text-transform: initial;
		}
	}

	.resources-back {
		cursor: pointer;
		text-align: left;
		display: flex;
		gap: 0.25em;
		font-size: inherit;
		order: -1;
		margin: var(--spacing);
		margin-bottom: calc(var(--spacing) / 2);
		border: none;
		background: none;
		padding: 0;

		@media (min-width: 1024px) {
			display: none;
		}

		svg {
			fill: var(--text-color);
			transform: rotate(90deg);
			margin-left: -2px;
		}
	}

	.resources-close{

		position: absolute;
		display: none;

		@media (max-width: 1023px) {
			display: none;
			background: var(--background);
			top: -60px;
			left: calc(0px - var(--spacing) - 19px);
			margin: 0;
			padding: 5px;
		}

		@media (min-width: 1024px) {
			// background: var(--background);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;

			width: 140px;
			top: -45px;
			height: 45px;
			right: 0;
		}

		span {
			display: none;
		}
		svg{
			
			&.mobile {
				width: 14px;
				height: 14px;

				stroke: currentColor;
				margin: 0;
				transform: rotate(180deg);
				background: var(--background);

				@media (min-width: 1024px) {
					display: none;
				}
			}

			&.desktop {
				width: 12px;
				height: 12px;

				fill: var(--text-color-inverted);
				transform: rotate(180deg);
				background: var(--background-inverted);

				position: absolute;
				right: 37px;
				top: 16px;

				@media (max-width: 1023px) {
					display: none;
				}
			}
		}
	}


	.two-columns {
		column-count: 2;
		column-gap: 0em;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			display: block;
			left: 50%;
			top: 0;
			background: #ffffff1a;
			width: 1px;
			height: calc(100% - 32px);
			top: 16px;
		}
	}

	.top {
		background: var(--background-inverted);
		color: var(--text-color-inverted);
		padding-top: 0.5em;

		header {
			@media (min-width: 1024px) {
				display: none;
			}
		}

		a {
			&:nth-of-type(4) {
				border-bottom: none;
			}
		}
	}

	.bottom {
		header,
		a {
			padding: 1em 0;
			border-bottom-color: #0228551a;

			&:nth-of-type(3) {
				border-bottom: none;
			}
		}

		.two-columns:after {
			background: #0228551a;
		}
	}
}

// show the nav menu on mobile
.resources.active,
#resources:target {
	visibility: visible;
	
	@media (min-width: 1024px) {
		display: block;
		scroll-margin-top: 45px;
	}

	@media (max-width: 1023px) {
		.resources-close {
			display: block;
		}

		.resources-body {
			opacity: 1;
			pointer-events: auto;
			transform: translate(-100%, 0);
		}
	}
}
