.card{
	background: #FFFFFF;
	border: 1px solid #0000001A;
	padding: var(--spacing);

	&:hover, &:focus-within{
		box-shadow: 0px 3px 6px #00000029;
		border: 1px solid #D8D8D8;
	}
	dt {
		font-weight: bold;
	}
	dd {
		margin-bottom: 0.75em;
		margin-left: 0;
		p:first-child {
			margin-top: 0;
		}
	}
}