figure{
	max-width: 100%;
	margin: 0;
	figcaption{
		font-size: 0.9rem;
		color: var(--text-grey);
	}
}

.wp-block-image {

	img{
		line-height: 0;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: cover;
		mask-repeat: no-repeat;
		mask-size: cover;
	}

	&.is-style-top-bracket img{
		-webkit-mask-image: var(--image-bracket-out-top);
		-webkit-mask-position: top;
		mask-image: var(--image-bracket-out-top);
		mask-position: top;
	}

	&.is-style-right-bracket img{
		-webkit-mask-image: var(--image-bracket-out-right);
		-webkit-mask-position: right;
		mask-image: var(--image-bracket-out-right);
		mask-position: right;
	}

	&.is-style-bottom-bracket img{
		-webkit-mask-image: var(--image-bracket-out-bottom);
		-webkit-mask-position: bottom;
		mask-image: var(--image-bracket-out-bottom);
		mask-position: bottom;
	}

	&.is-style-left-bracket img{
		-webkit-mask-image: var(--image-bracket-out-left);
		-webkit-mask-position: left;
		mask-image: var(--image-bracket-out-left);
		mask-position: left;
	}


	&.is-style-inner{

		&.is-style-top-bracket img{
			-webkit-mask-image: var(--image-bracket-in-top);
			mask-image: var(--image-bracket-in-top);
		}

		&.is-style-right-bracket img{
			-webkit-mask-image: var(--image-bracket-in-right);
			mask-image: var(--image-bracket-in-right);
		}

		&.is-style-bottom-bracket img{
			-webkit-mask-image: var(--image-bracket-in-bottom);
			mask-image: var(--image-bracket-in-bottom);
		}

		&.is-style-left-bracket img{
			-webkit-mask-image: var(--image-bracket-in-left);
			mask-image: var(--image-bracket-in-left);
		}		
	}

}
