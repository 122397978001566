.pos-searchbar-pattern {
    margin: 1.5rem 0;
    border-top: 1px solid var(--text-color);
    border-bottom: 1px solid var(--text-color);
    padding: 1.5rem 0;
}
.mu-programs-search-searchbar {
    padding: 1.5rem 0;
    input {
        width: calc(100% - 120px);
        padding: 15px;
        margin-right: 20px;
        .is-root-container & {
            width: calc(100% - 122px);
        }
    }
    button {
        background: none;
        border-radius: 0;
        border: 1px solid var(--text-highlight);
        width: 100px;
        padding: 15px;
        color: var(--text-highlight);
        &:after {
            content: " ";
            width: calc(1.125rem - 2px);
            display: inline-block;
            height: calc(1.125rem - 2px);
            margin-bottom: -.15em;
            background: currentColor;
            -webkit-mask: var(--search);
            mask: var(--search);
        }
        &:focus-visible, &:focus, &:hover{
			text-decoration: none;
			background: var(--text-highlight);
			color: #fff;
		}
		&:active{
			background: var(--text-color);
			color: #fff;
		}
    }
}