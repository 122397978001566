.content .tribe-community-events {
	padding: 2em 0 0 0;
	border: none;
}

.tribe-events-content p {
	font-size: 1rem !important;
}

.content table.tribe-section-content td {
	padding: 0;
}

.content table.tribe-section-content tr:nth-of-type(2n) {
	background: none;
}

.content #tribe-events,
.content #tribe-events-pg-template,
.content.tribe-events-pg-template {
	padding: 0;
}


.tribe-events.alignwide {
	margin: 0;
}
.content .tribe-common .tribe-common-l-container,
.content .tribe-common--breakpoint-medium.tribe-events .tribe-events-l-container,
.content .tribe-common--breakpoint-medium.tribe-common .tribe-common-l-container {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}


@media(min-width: 1024px){
	.tribe-block__archive-events {
	  margin-right: calc(0px - var(--columnWidth) - var(--gutterWidth));
	}
  }
