.wp-block-mu-events{
	position: relative;

	@media(min-width:1024px){
		body &.events {
			margin-right: calc(0px - var(--columnWidth) - var(--gutterWidth)) !important;
			margin-left: calc(0px - var(--columnWidth) - var(--gutterWidth)) !important;
		}
	}

	.events-wrapper {
		display: flex;
		flex-wrap: nowrap;
		gap: 1em;
		margin-right: calc(0px - var(--spacing) );

		scroll-snap-type: x mandatory;	
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
		overflow-y: hidden;

		@media(min-width:1000px){
			margin-right: 0;
		}
	}

	.component-title{
		z-index: -1;
	}
	.events-all {
		margin-top: var(--spacing);

		@media (min-width: 1024px) {
			position: absolute;
			top: 0.3rem;
			right: var(--spacing);
			margin-top: 0;
		}
	}	
}

.event{
	position: relative;
	scroll-snap-align: center;
	flex-shrink: 0;
	max-width: 320px;
	width: 80vw;
	aspect-ratio: 0.8;
	background: #002855;

	@media (min-width: 1000px){
		aspect-ratio: 1/1;
		max-width: initial;
		flex-shrink: 1;
	}


	// &.has-no-image-1{
	// 	background: #002855;
	// }

	&.has-no-image-2{
		background:  #0d64b2;
	}

	&-image{

		line-height: 0;
		width: 100%;
		height: 100%;

		&:before {
			.has-image &{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 50%;
				z-index: 1;
				background: transparent
					linear-gradient(0deg, #12060679 0%, #80808000 100%) 0% 0% no-repeat
					padding-box;
				@supports (mask: url("")) {
					height: 50%;
					background: rgba(0, 0, 0, 0.8);
					backdrop-filter: blur(10px);
					mask-image: linear-gradient( to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0, 1) );
					-webkit-mask-image: linear-gradient( to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0, 1) );
				}
				
			}
		}


		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

	}

	&-details{
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		color: #fff;
		z-index: 1;
		padding: calc(0.5 * var(--spacing) );
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	p{
		margin-bottom: 0;
	}

	a{
		color: #fff;
		text-decoration: none;
		&:focus, &:hover{
			text-decoration: underline;
		}
		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}				
	}

	&-title{
		font-style: italic;	
		margin-top: 0;
		line-height: 1.2;	
	}

}
