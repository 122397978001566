.site-footer {
	border-top: 2px solid var(--background-grey);
	position: sticky;
	top: 100vh;
	@media print{
		display: none;
	}
}

.footer-primary {
	max-width: 1400px;
	margin: 0 auto;
	color: var(--text-color);
	width: 100%;

	svg {
		fill: currentColor;
	}

	.primary-inner {
		display: flex;
		flex-direction: column;
		@media (min-width: 1024px) {
			flex-direction: row;
			flex-wrap: nowrap;
		}
	}
	.branding {
		display: flex;
		flex-wrap: wrap;

		@media (max-width: 1023px) {
			flex-direction: column;
			align-items: center;
			padding-bottom: var(--spacing);
		}

		@media (min-width: 1024px) {
			flex-direction: row;
			width: 50%;
			padding: 50px 0 100px calc( var(--spacing-large) - 40px );
		}

		.wp-block-site-logo {
			margin: var(--spacing) 0;

			@media (min-width: 1024px) {
				width: 100%;
				padding: initial initial var(--spacing) initial;
				margin-top: 0;
			}

			img {
				width: 210px;
			}
		}

		.vcard {
			width: auto;

			@media (max-width: 1023px) {
				text-align: center;
			}

			.adr {
				font-style: normal;

				@media (min-width: 1024px) {
					padding-right: var(--spacing);
					border-right: 2px solid var(--background-grey);
				}

				.street-address,
				.postal-code {
					display: block;
				}
			}

			.tel {
				display: block;
				margin-top: calc(var(--spacing) / 2);

				text-decoration: none;

				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.contact {

			@media (min-width: 1024px) and (max-width: 1200px){
				justify-content: center;
				align-items: center;
				flex-grow: 1;
			}

			.wp-block-button  {
				@media (max-width: 1023px) {
					margin-top: var(--spacing);
				}
				@media (min-width: 1024px) {
					
					margin: 0;
					margin-bottom: -2em;
				}
				@media (min-width: 1200px){
					margin-left: var(--spacing);
				}
			}
		}

		.social {
			margin: 25px 0;
			width: 100%;
			display: flex;
			justify-content: center;

			@media (min-width: 1024px) {
				justify-content: start;
				//margin: 0 0;
			}

			&-inner {
			}

			a {
				text-decoration: none;
				white-space: nowrap;
				width: 24px;
				height: 24px;
				display: inline-block;
				padding: 0;
				color: var(--text-color);
				margin: 0 0.5em;

				@media (min-width: 1024px) {
					margin: 0 1.2em 0 0;
				}

				&:after {
					display: none;
				}

				&:hover,
				&:focus {
					svg {
						color: var(--text-highlight);
					}
				}
			}

			span {
				display: none;
			}

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.footer-nav {
		background: var(--background-inverted);
		font-size: 1.125em;

		@media (max-width: 1023px) {
			text-align: center;
			padding: 1em 0;
		}

		@media (min-width: 1024px) {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&:before {
			@media (min-width: 1024px) {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: 50%;
				height: 100%;
				background: var(--background-inverted);
				z-index: -1;
			}
		}

		&-inner {
			@media (min-width: 1024px) {
				padding-left: var(--spacing-large);
				column-count: 2;
			}
		}

		a {
			color: var(--text-color-inverted);
			font-family: var(--serif);
			display: block;
			text-decoration: none;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;

			@media (max-width: 1023px) {
				margin: 1em 0;
			}

			@media (min-width: 1024px) {
				padding: 0 0 var(--spacing) 0;
			}

			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.footer-supplemental {
	background: var(--background-grey);
	margin: 0;
	padding: 0;

	&-inner {
		max-width: 1400px;
		margin: 0 auto;
		width: 100%;
		padding: calc(var(--spacing) / 2) 0;
		display: flex;
		justify-content: space-between;
		font-size: 0.875em;
		flex-direction: column;
		align-items: center;

		@media (min-width: 1024px) {
			padding: calc(var(--spacing) / 2) var(--spacing-large);
			flex-direction: row;
		}
	}

	a {
		text-decoration: none;
		color: var(--text-color);
		font-weight: 600;
		margin: 0.5em 0;
		text-align: center;

		@media (max-width: 1023px) {
			display: block;
		}

		@media (min-width: 1024px) {
			margin: 0 var(--spacing) 0 0;
		}

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	p {
		line-height: 1.5;
		margin: 0;
		@media (max-width: 1023px) {
			margin: 1em 0;
		}
	}
}
