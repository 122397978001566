.wp-block-button{
	@mixin chevron {
		.wp-block-button__link:after{
			content: "   ";
			width: 0.5em;
			display: inline-block;
			height: 1em;
			margin-bottom: -0.15em;
			margin-left: 1.5em;
			background: currentColor;	
			--chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' data-icon='chevron-right' data-prefix='fal' viewBox='0 0 256 512'%3E%3Cpath fill='currentColor' d='m18 36-8 8c-4 4-4 12 0 17l196 195L10 451c-4 5-4 13 0 17l8 8c4 4 12 4 16 0l212-212c4-4 4-12 0-16L34 36c-4-4-12-4-16 0z'/%3E%3C/svg%3E");			
			-webkit-mask: var(--chevron);
			mask: var(--chevron);

			
		}
	}

	.wp-block-button__link,
	.wp-block-button__link:visited{
		--color: var(--text-highlight);
		font-size: 1rem;
		padding: 0;
		margin: 0;
		border: 0;
		background: none;
		font-family: var(--serif);
		color: var(--color);
		border-radius: initial;
		line-height: 1.5;
		text-decoration: none;
		position: relative;
		display: inline-block;

		&:focus-visible, &:focus, &:hover{
			--color: var(--text-color);
			text-decoration: underline;
		}

	}

	&.is-style-hero .wp-block-button__link,
	&.is-style-hero .wp-block-button__link:visited{
		background: #fff;
		box-shadow: 0px 12px 32px #0000005a;
		padding: 1rem 40px;

		font-size: 0.8125rem;
		font-family: var(--sansserif);
		font-weight: bold;
		line-height: 1.8;
		letter-spacing: 0.65px;
		text-align: center;

		--outline-color: var(--text-highlight);

		&:focus-visible, &:focus, &:hover{
			text-decoration: none;
			background: var(--text-highlight);
			color: #fff;
		}

		&:active{
			background: var(--text-color);
			color: #fff;
		}		

	}

	&.is-style-in-page,
	&.is-style-in-page-has-chevron {
		.wp-block-button__link,
		.wp-block-button__link:visited{
			background: #fff;
			padding: 1rem 40px;
			border: 1px solid var(--text-highlight);

			font-size: 0.875rem;
			font-family: var(--sansserif);
			font-weight: bold;
			letter-spacing: 0.7px;
			text-align: center;
			--outline-color: var(--text-highlight);

			&:focus-visible, &:focus, &:hover{
				background: var(--text-highlight);
				--color: #fff;
				text-decoration: none;
			}

			&:active{
				background: var(--text-color);
				--color: #fff;
			}		
		}
	}

	&.is-style-in-page-has-chevron{
		@include chevron;	
	}
	
	&.is-style-in-page-link {
		@include chevron;	
		.wp-block-button__link{
			font-size: 0.875rem;
			font-family: var(--sansserif);
			font-weight: bold;
		}
	}

	&.is-style-in-page,
	&.is-style-in-page-has-chevron{
		margin: 1.25rem 0;
	}

	&.is-style-ghost .wp-block-button__link {
		background: none;
		padding: 1rem 40px;
		border: 1px solid #fff;
		font-size: .875rem;
		font-family: var(--sansserif);
		font-weight: 700;
		letter-spacing: .7px;
		text-align: center;
		--outline-color: var(--text-highlight);
	}
}
