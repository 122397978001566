.wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
	background: none;
	bottom: initial;
	left: initial;
	margin-bottom: initial;
	color: initial;
	max-height: initial;
	overflow: initial;
	font-size: 0.8rem;
	padding: 0 8px 8px;
	position: relative;
	flex-grow: 0;
	flex-basis: initial;
	opacity: 0.8;
  }