.wp-embed-responsive .wp-has-aspect-ratio iframe{
	width: 100%!important;
	height: 100%!important;
}

@supports (aspect-ratio: auto) {
  iframe[src*="livestream.com"],
  .wp-block-embed.is-type-video iframe{
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16/9;
    border: 0 none;
  }
}