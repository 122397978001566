.datapoints {
	gap: 2em;
	.wp-block-column {
		color: var(--header-color);
		text-align: center;
		margin: 1em 0;
		border: 1px solid var(--text-highlight);
		padding: 3em 0;
		font-size: 1rem;
	

		strong {
			font-size: 6rem;
			font-family: var(--serif);
			display: block;
			margin-top: -0.3em;
			margin-bottom: -.5em;			
			.block-editor-block-list__layout & {
				margin-bottom: -0.3em;
			}
		}
		p {
			margin: 0;
		}
	}
	// high specificity to override the WP styling.
	&.wp-block-columns > .wp-block-column:not(:only-child) {
		@media (min-width: 600px) and (max-width: 781px) {
			flex-basis: 100% !important;
		}
	}
	> .wp-block-column:nth-child(2n) {
		@media (min-width: 400px) and (max-width: 781px) {
			margin-left: 0 !important;
		}
	}
}
  