.more-to-explore{
	.component-title{
		@media(min-width: 600px){
			text-decoration: underline;
			text-underline-offset: 0.75em;
			margin-bottom: 2em;
		}
	}

	.wp-block-columns{
		gap: var(--gutterWidth);
		flex-wrap: nowrap;
		overflow-x: auto;
		scroll-snap-type: x mandatory;	

		.wp-block-column{
			min-width: 270px;
			scroll-snap-align: center;
			// &:nth-child(2n),
			// &:not(:first-child){
			// 	margin-left: var(--wp--style--block-gap,2em);
			// }
		}
	}

	.wp-block-media-text{
		height: 100%;
		grid-template-rows: 240px;
		position: relative;
		&:focus img,
		&:hover img{
			scale: 1.05;
			
		}
		&.alignwide{
			margin: 0 !important;
		}	
	
	}

	figure{
		height: 240px;
		overflow: hidden;
		img{
			transition: scale 0.25s ease;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.wp-block-media-text__content{
		border: 1px solid #0000001A;
		background: #fff;
		border-top: none;
		padding: var(--spacing) !important;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.tile-title{
		margin: 0;
	}

	p{
		flex-grow: 1;
		margin-bottom: 2em;
	}

	.wp-block-button .wp-block-button__link{
		position: revert;
	}

	a:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}
