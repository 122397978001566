.castos-player button span {
	position: absolute;
	left: -10000px;
}
.podcast_player .player-panel {
	visibility: hidden;
	&.open {
		visibility: visible;
	}
}
.castos-player .player-panel .player-panel-row button:focus {
	cursor: pointer;
	background-color: #df4e4f !important;
}
.castos-player .apple_podcasts:focus{
	outline: var(--outline-size) var(--outline-style) currentColor;
  	outline-offset: var(--outline-offset,var(--outline-size));
}
