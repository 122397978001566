/*
 * Breadcrumbs style
 * */

 .wp-block-mu-hero + .breadcrumbs {
    padding-top: 0;
}

.breadcrumbs {
    display: none;

    @media (min-width: 1024px) {
        display: inline-flex;
        width: 100%;
        padding: 1.25rem var(--spacing);
        justify-content: flex-start;
        flex-direction: row;
    }

    @media print {
		display: none;
	}

    a {
        font-size: 1rem;
        padding: 0;
        text-decoration: none;
        align-items: center;
        -webkit-mask-image: none;
        mask-image: none;
        white-space: nowrap;
        margin: 0 0.8rem;

        &:not(:first-child)::before {
            content:"";
            color: #000;
            pointer-events: none;
            margin-left: -0.6rem;
            -webkit-mask-image: none;
            mask-image: none;
            background: #000;
            height: 0.75em;
            width: 1.3px;
            position: absolute;
            transform:rotate(19deg) translateY(.4em);
            
        }

        &.home {
            display: inline-block;
            height: fit-content;
            margin-left: 0;

            &:before {
                display: inline-block;
                content: "";
                -webkit-mask-image: var(--home-icon);
                mask-image: var(--home-icon);
                height: 0.8rem;
                width: 1rem;
                background-color: currentColor;
                margin-right: 0.5em;
            }
        }

        &:last-child:after {
            display: none;
        }
        
        &:last-of-type {
            text-overflow: ellipsis;
            overflow: hidden;
        }

    }

    .middle-crumbs {
        position: relative;

        a {
            display: none;
        }
        

        &:before {
            content: "...";
            font-size: 1rem;
            padding: 0;
            color: #000;
            margin: 0 0.8rem;
        }

        &::after {
            content: "/";
            color: #000;
            font-weight: bold;
            pointer-events: none;
            mask-image: none;
            background: none;
            position: absolute;
            right: 100%;
        }
    }
}
 