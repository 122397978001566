.socialposts {
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    grid-gap: 16px;
    grid-template-rows: masonry;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    > * {
        align-self: start;
    }

    &.loadedAll + .loadMoreBtn {
        display: none;
    }
    & ~ .connect-with-us {
        display: none;
    }
    &.loadedAll ~ .connect-with-us {
        display: block;
    }
}

.socialpost {
    --background: #002855;
    --color: #fff;
    --subcolor: #fff;

    border: 1px solid #0000001a;
    position: relative;
    break-inside: avoid;
    margin: 0 0 var(--spacing) 0;
    background: var(--background);
    color: var(--color);
    padding: 0;

    &:hover,
    &:focus-within {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #d8d8d8;
    }

    &:nth-child(n + 12) {
        display: none;
    }

    &.has-media {
        --background: #fff;
        --color: #002855;
        --subcolor: #6F6F71;
    }

    &.youtube .post-media{
        position: relative;
        z-index: 1;

        .fallback{
            display: block;
            aspect-ratio: 16/9;

            img{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                object-fit: cover;
            }
        }
    }



    .post-media {
        line-height: 0;

        img {
            width: 100%;
            height: auto;
        }

        iframe,
        video {
            width: 100%;
            height: auto;
            aspect-ratio: 16/9;
        }
    }

    .post-body {
        padding: var(--spacing) ;
    }

    .post-time {
        margin-top: 30px;
        font-size: 0.75rem;
        margin-bottom: 0;
        a{
            color: var(--subcolor);
            &::before{
                content:"";
                position: absolute;
                top:0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }

    .post-platform {
        width: 16px;
        height: auto;
        position: absolute;
        bottom: 16px;
        right: 16px;
    }

    .loadedMore & {
        &:nth-child(n + 12) {
            display: block;
        }

        &:nth-child(n + 24) {
            display: none;
        }
    }

    .loadedAll & {
        &:nth-child(n + 12) {
            display: block;
        }

        &:nth-child(n + 24) {
            display: block;
        }
    }

    .post-account {
        position: absolute;
        bottom: var(--spacing);
        right: var(--spacing);
        display: block;
        width: 30px;
        height: 20px;

        svg {
            color: var(--background-inverted);
            width: 100%;
            height: 100%;
        }
    }

    &:not(.has-media).twitter{
        --subcolor: #fff8;
        svg {
            color: #fff;
        }
    }

    iframe {
        border: none;
    }
}

.loadMoreBtn {
    margin: 0 auto;
    display: block;
    background: none;
    border-radius: 0;
    border: 1px solid var(--text-highlight);
    width: 100%;
    padding: 15px;
    color: var(--text-highlight);
    font-family: var(--sansserif);
    cursor: pointer;
    font-weight: bold;
    &:focus-visible,
    &:focus,
    &:hover {
        text-decoration: none;
        background: var(--text-highlight);
        color: #fff;
    }

    &:active {
        background: var(--text-color);
        color: #fff;
    }
}

.connect-with-us {
    .connect-header {
        text-align: center;
        margin-bottom: 0;
        @media (min-width: 768px) {
            margin-bottom: 1em;
        }
    }

    .social-icons {
        margin: 0 auto;
        font-size: 48px;
        display: flex;
        gap: 0.5em;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        list-style: none;

        li {
            background: none;
            margin: 0;
            transition: transform 0.1s ease;
            &:hover {
                transform: scale(1.1);
            }
            a {
                width: 0.5em;
                height: 0.5em;
                display: block;
                color: var(--header-color);
                margin: 0 0 1em;
                @media (min-width: 500px) {
                    width: 0.75em;
                    height: 0.75em;
                }
                @media (min-width: 768px) {
                    width: 1.25em;
                    height: 1.25em;
                    margin: 0 0.1em 1em;
                }
            }
            svg {
                fill: var(--header-color);
                width: 100%;
                height: 100%;
            }
        }
    }
}
