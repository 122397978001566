@at-root .gform_wrapper input[type="submit"],
.gform_wrapper .gform_next_button,
.gform_wrapper .gform_previous_button{
	background: #fff;
	padding: 1rem 40px;
	border: 1px solid var(--text-highlight);
	color: var(--text-color);
	font-size: 0.875rem;
	font-family: var(--sansserif);
	font-weight: bold;
	letter-spacing: 0.7px;
	text-align: center;
	--outline-color: var(--text-highlight);

	&:focus-visible, &:focus, &:hover{
		background: var(--text-highlight);
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}

	&:active{
		background: var(--text-color);
		--color: #fff;
	}		
}

@at-root .gform_wrapper.gravity-theme .gform_validation_errors > h2,
.gform_wrapper.gravity-theme .gform_validation_errors .validation_error_msg > h2 {
	color: #c02b0a;
	font-size: 1.2rem;
	margin: 0.5rem 0;
	padding: 0;
	font-family: var(--sansserif);
}

// alter font settings for form pages
.content .gform_wrapper.gravity-theme .gf_progressbar_title {
	font-size: 1rem;
	color: #000;
}

// for legacy styling of new validation messaging
.gform_legacy_markup_wrapper .gform_validation_errors .validation_error_msg > h2 {
	color: #790000;
	font-size: 1rem;
	font-weight: bold;
	&:before {
		background-color: transparent;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIGZpbGw9IiM3OTAwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExIDE5YTggOCAwIDEgMCAwLTE2IDggOCAwIDAgMCAwIDE2WiIgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjEuNSIvPjxwYXRoIGQ9Ik0xMiA2aC0ydjZoMlY2Wk0xMiAxNGgtMnYyaDJ2LTJaIiBmaWxsPSIjRkZGIi8+PC9zdmc+);
		background-position: 50%;
		background-repeat: no-repeat;
		content: "";
		display: inline-block;
		height: 22px;
		margin: 0 .2rem 0 -.5rem;
		vertical-align: sub;
		width: 22px;
	}
}