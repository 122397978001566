.wp-block-cover.experience-monmouth {
	background: var(--background-inverted);
	width: revert;
	min-height: 600px;

	@media(min-width:1024px){
		margin: 0 calc(0px - var(--columnWidth) - var(--gutterWidth))
	}

	.wp-block-cover__gradient-background.has-background-dim-0 {
		background: transparent linear-gradient(180deg, #12060679 0%, #80808000 100%) 0% 0% no-repeat padding-box;
		opacity: 1;
		bottom: revert;
		height: 80%;
	}

	.wp-block-cover__inner-container {
		color: #fff;
	}

	h2{
		text-shadow: 0px 4px 32px #000000;
		font-weight: 400;
		color: #fff;
	}

	.wp-block-buttons {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 33px;
		box-sizing: border-box;

		background: #ffffff1a;
		box-shadow: 0px 12px 32px #0000005a;
		border: 2px solid #ffffff34;
		padding: 40px;
		margin-top: 66px;

		@media (min-width: 1024px) {
			flex-direction: row;
			width: 80%;
			margin: 33px auto 0 auto;
		}

		.wp-block-button {
			flex-grow: 1;
			@media(max-width: 1023px){
				width: 100%;
			}

			a {
				z-index: 10;
				display: block;
			}
		}
	}

	&.alt-experience {
		p, .headline {
			max-width: 900px;
			margin: 3rem auto 1rem;
			text-align: center;
		}
		.wp-block-buttons {
			background: none;
			box-shadow: none;
			border: none;
			margin-top: 0;
		}
		
	}
}