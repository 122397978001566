.wp-block-mu-badges-badge-widget{
	display: flex;
	
	&.is-style-horizontal{
		flex-direciton: row;
		flex-wrap: wrap;
		align-items: center;
		flex-position: center;
	}
	&.is-style-vertical{
		flex-direction: column;
		align-items: center;
	}

	img{
		width: 100px;
		height: auto;
		margin: 1em;
	}

	&.has-small-badge-size img{
		width: 75px;
	}
	
	&.has-large-badge-size img{
		width: 130px;
	}
	
	&.has-huge-badge-size img{
		width: 180px;
	}
}