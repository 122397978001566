.wp-block-media-text{

	// taek care of the vertical alignment
	&.is-style-vertical {
		grid-template-columns: 100% !important;
		margin: 0 !important;

		.wp-block-media-text__media {
			grid-column: 1;
			grid-row: 1;
			width: 100%;
		}

		.wp-block-media-text__content {
			grid-column: 1;
			grid-row: 2;
			padding: 0;
		}

		// vertical, make the image go below the text
		&.has-media-on-the-right{

			.wp-block-media-text__media {
				grid-row: 2;
			}

			.wp-block-media-text__content {
				grid-row: 1;
			}

		}

	}


	// add in arrows as needed
	&.is-style-bracket{
		> figure img{
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-size: cover;
			mask-repeat: no-repeat;
			mask-size: cover;
		}

		// left side image
		&:not(.is-style-vertical) > figure img{
			-webkit-mask-image: var(--image-bracket-out-bottom);
			-webkit-mask-position: bottom;
			mask-image: var(--image-bracket-out-bottom);
			mask-position: bottom;
			
			@media(min-width: 600px){
				-webkit-mask-image: var(--image-bracket-out-right);
				-webkit-mask-position: right;
				mask-image: var(--image-bracket-out-right);
				mask-position: right;
			}
		}

		// right side image
		&.has-media-on-the-right:not(.is-style-vertical) > figure img{
			@media(min-width: 600px){
				-webkit-mask-image: var(--image-bracket-out-left);
				-webkit-mask-position: left;
				mask-image: var(--image-bracket-out-left);
				mask-position: left;
			}
		}


		// Vertical - image on top
		&.is-style-vertical > figure img{
			-webkit-mask-image: var(--image-bracket-out-bottom);
			-webkit-mask-position: bottom;
			mask-image: var(--image-bracket-out-bottom);
			mask-position: bottom;
		}

		// Vertical - image on bottom
		&.is-style-vertical.has-media-on-the-right > figure img{
			-webkit-mask-image: var(--image-bracket-out-top);
			-webkit-mask-position: top;
			mask-image: var(--image-bracket-out-top);
			mask-position: top;
		}		

	}
	

	.wp-block-media-text__content{
		padding: 0 0 0 var(--spacing);
		.has-large-font-size {
			font-size: 2.625rem !important;
			font-weight: 400;
			font-family: var(--serif);
			color: var(--header-color);
			margin: 0.5em 0;
		}
	}
	
	&.has-media-on-the-right .wp-block-media-text__content{
		padding: 0 var(--spacing) 0 0;
	}


	&.alignwide {
		.wp-block-media-text__content{
			padding: 0 var(--spacing);
		}
		.content-top & {
			margin: 0 calc(0px - var(--spacing)) !important;
		}
	}
}