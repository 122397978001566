.eyebrow.mobile a.skipnav {
	display: none;
	visibility: hidden;
}

.eyebrow {
	@media print {
		display: none;
	}

	@media (max-width: 1023px){
		flex-grow: 0;
	}
	
	@media (min-width: 1024px) {
		flex-grow: 0;
		button,
		a {
			position: relative;
			top: initial;
		}
	}

	.eyebrow-inner {
		padding: 1px 0;
	}

	.general {
		@media (min-width: 1024px) {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin: 0;
			height: 100%;
		}

		button,
		a {
			cursor: pointer;
			font-family: inherit;
			text-transform: inherit;
			font-size: inherit;
			background: none;
			border: none;
			text-decoration: none;
			font-weight: inherit;
			color: var(--text-color-inverted);
			display: block;
			padding: calc(var(--spacing) / 2) var(--spacing);
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;

			&:nth-child(2) {
				@media(max-width: 1023px){					
					// padding-top: var(--spacing);
				}
			}
			&:nth-child(4) {
				@media(max-width: 1023px){					
					margin-bottom: var(--spacing);
					&:after {
						content: "";
						width: 100%;
						border-bottom: 1px solid #FFFFFF1A;
						position: absolute;
						bottom: calc(0px - var(--spacing) /2 );
						left: 0;
					}
				}
				@media (min-width: 1024px){
					border-right: 1px solid #FFFFFF1A;
				}
			}

			&:last-child {
				@media(max-width: 1023px){					
					margin-bottom: var(--spacing);
				}
			}

			&:focus,
			&:hover {
				background: #345276;
			}

			@media (min-width: 1024px) {
				width: auto;
				height: 100%;
				padding: 0 25px;
				align-items: center;

				&:last-of-type {
					// padding-right: var(--spacing);
					// margin-right: calc(0px - (var(--spacing) / 2));
				}
			}
		}
	}
	p:empty {
		display: none;
	}

}
