@mixin grid-media-queries {
	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 33% 1fr;
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: calc((2 * var(--columnWidth)) + (var(--gutterWidth)) - 1px) 1fr;
	}
}
.wp-block-mu-contact {
	border: 1px solid #002855;
	--spacing: 24px;
	margin: 0 auto;
	position: relative;
	word-wrap: break-word;
	hyphens: auto;
	background: #fff;

	&.has-image{
		@include grid-media-queries;
	}
	&:has(.wp-block-image){
		@include grid-media-queries;
	}

	+ .wp-block-mu-contact {
		margin: var(--spacing) 0;
	}

	.imageWrapper {
		@media (min-width: 1024px) {
			@at-root :not(.is-style-longtext)#{&} {
				grid-row: 1 / span 2;			
			}
		}
	}

	figure {
		width: 100%;
		aspect-ratio: 1.3;
		object-fit: cover;
		margin: 0;
		@media (min-width: 1024px) {
			height: 100%;
			@at-root .is-style-longtext#{&} {
				height: auto;
				aspect-ratio: 1;
			}
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.nameAndTitle {
		margin: 0 var(--spacing) var(--spacing);

		p:last-of-type {
			margin-bottom: 0;
		}
		@media (min-width: 768px) {
			align-self: end;
			margin-bottom: 0;
		}
	}

	.bioText {
		margin: 0 var(--spacing) var(--spacing);

		@media (min-width: 768px) {
			grid-column: 2;
			@at-root .is-style-longtext#{&}{
				grid-column: 1 / span 2;
			}
		}
	}

	.name {
		line-height: 1.5;
		a:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.title {
		margin-top: 0;
		text-align: left;
	}

	.dept,
	.office {
		font-weight: 100;
		@media (min-width: 768px) {
			display: inline-block;
			margin-right: var(--spacing);
		}
	}

	.phone,
	.email,
	.appointment {
		z-index: 2;
		position: relative;
		@media (min-width: 768px) {
			display: inline;
			margin-right: var(--spacing);
		}
	}
}
