// search
.wp-block-search {
    .search-button {
        background: none;
        border-radius: 0;
        border: 1px solid var(--text-highlight);
        width: 100px;
        padding: 15px;
        color: var(--text-highlight);
        &:after {
            content: " ";
            width: calc(1.125rem - 2px);
            display: inline-block;
            height: calc(1.125rem - 2px);
            margin-bottom: -.15em;
            background: currentColor;
            -webkit-mask: var(--search);
            mask: var(--search);
        }
        &:focus-visible, &:focus, &:hover{
			text-decoration: none;
			background: var(--text-highlight);
			color: #fff;
		}
		&:active{
			background: var(--text-color);
			color: #fff;
		}
    }
    // combobox for taxonomy search
    .taxonomy-combobox-wrapper{
        margin: 1rem 0;
        width: 300px;
        position: relative;
        .taxonomy-label {
            margin-bottom: 0.25rem;
            cursor: default;
        }
        
        .taxonomy-combobox {
            min-height: 2em;
            width: 300px;
            position: relative;
            padding: 0.5rem 1rem;
            border-radius: .25rem;
            border: 1px solid #e5e5e5;
            &:after {
                content: "▾";
                position: absolute;
                right: 0.5rem;
                line-height: .7;
                font-size: 2rem;
            }
            &:hover {
                cursor: pointer;
            }
            &[aria-expanded="true"] + .listbox {
                display: block;
            }
        }
        .listbox {
            display: none;
            position: absolute;
            left: 0;
            z-index: 10;
            height: auto;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid #e5e5e5;
            width: 100%;
        }
        .taxonomy {
            padding: 1rem 3rem 1rem 1rem;
            margin-bottom: 0;		
            border-bottom: none;
            background: #fff;
            border-top: 1px solid #e5e5e5;
            position: relative;

            &:hover, 
            &.option-current {
                background: var(--text-highlight);
                color: #fff;
            }
            &:hover {
                cursor: pointer;
            }
            &:first-child {
                border-top: none;
            }
            &[aria-selected="true"]:before {
                content: "";
                position: absolute;
                height: 100%;
                width: 3rem;
                right: 0;
                top: 0;
                color: #fff;
                background-color: var(--header-color);
                -webkit-mask: var(--checkmark);
                mask: var(--checkmark);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                mask-repeat: no-repeat;
                mask-size: 50%;
                mask-position: center;
            }
            &[aria-selected="true"]:hover:before,
            &.option-current[aria-selected="true"]:before{
                color: var(--text-highlight);
                background-color: #fff;
            }
            &[data-level="2"] {
                padding-left: 1.5rem; 
            }
            &[data-level="3"] {
                padding-left: 2rem; 
            }
            &[data-level="4"] {
                padding-left: 2.5rem; 
            }
        }
        .no-taxonomy {
            padding: 1rem;
            margin-bottom: 0;
            background: #fff;
            border: 1px solid #e5e5e5;
            p {
                margin: 0;
                font-size: 1rem;
                color: #000;
            }
        }
    }

    // wrapper for checkbox
    .taxonomy-checkbox-wrapper{
        margin-top: 0.5rem;

        input {
            margin-left: 0;
        }
        label:hover,
        input:hover {
            cursor: pointer;
        }
    }
}