
.wp-block-programs-of-study{
	form {
		width: 100%;
		position: relative;
		@media (min-width: 768px) {
			width: 80%;
		}
	}
	.programs-filters{
		display: flex;
		gap: var(--spacing);
		margin: var(--spacing) 0;
		@media(max-width: 767px){
			flex-direction: column;
		}
		.sub-prog {
			margin-left: 2.5rem;
			width: calc(100% - 2.5rem);
			right:0;
		}
	}
	.radio-boxes{
		width: 100%;
		min-height: 3em;
		height: 3em;
		position: relative;
		
		&:hover,
		&:focus,
		&:focus-within{
			div{
				left: 0;
			}			
		}

		legend{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: calc(100% - 24px);;
			&:after {
				content: "▾";
				position:absolute;
				right: 0;
				line-height: 0.7;
				font-size: 2rem;
			}
		}

		div{
			position: absolute;
			top: calc(3em - 5px);
			left: -10000px;
			background:#fff;
			box-shadow: 0px 3px 6px #00000029;
			border: 1px solid #D8D8D8;
			border-top: none;
			z-index: 5;
			width: 100%;
			padding: var(--spacing);
		}
		label{
			display: block;
			width: 100%;
			cursor: pointer;
			&.disabled {
				color: var(--text-grey);
				text-decoration: line-through;
			}
		}

	}
	.clearFilters {
		position: relative;
		border: none;
		background: 0 0;
		cursor: pointer;
		height: auto;
		padding: 0;
		text-align: left;
		display: block;
		left: calc(100% - 7em);
		width: 8rem;
		color: var(--text-highlight);
		@media (min-width: 768px) {
			position: absolute;
			padding: 1em 0;
			right: calc(0% - 9rem);
			left: unset;
		}
		&:before {
			color: #fff;
			content: "";
			width: 0.875rem;
			display: inline-block;
			height: 0.875rem;
			margin-right: 0.4rem;
			top: 0.15rem;
			position: relative;
			background: var(--text-highlight);
			-webkit-mask: var(--xclose);
			mask: var(--xclose);
		}
	}

	.countAndSort {
		display: flex;
		width: 100%;
		margin: var(--spacing) 0 0.5rem;
		justify-content: space-between;
		flex-wrap: wrap;
		@media (min-width: 768px) {
			width: 125%;
		}
	}
	.expand {
		width: 100%;
		flex-basis: 100%;
	}
	.count-wrapper{
		flex: 1;
		display: flex;
		justify-content: left;
		color: var(--text-grey);
		margin-bottom: 1.2rem;
		align-items: center;
		p{
			margin: 0;
		}
		&[aria-busy]:after {
			display: block;
			content: url("/wp-includes/images/spinner.gif");
			padding-left: 1rem;
			padding-top: 0.25rem;
		}
	}
	.sort-radios {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		margin-bottom: 0.5rem;
		flex-basis: 160px;
		fieldset {
			margin-right: 0;
		}
		input {
			margin: 0.25rem 0.1rem 0.25rem 0.25rem;
			&:nth-of-type(2) {
				margin-left: 1rem;
			}
		}
		label:last-child {
			margin-right: 0.25rem;
		}
	}

	.component-title{
		display: block;
		margin-bottom: var(--spacing);
	}

	.progsearch{
		width: 100%;
		padding: 15px;
		&:after {
			content: " ";
			width: calc(1.125rem - 2px);
			display: inline-block;
			height: calc(1.125rem - 2px);
			margin-bottom: -.15em;
			color: #fff;
			background: var(--text-highlight);
			-webkit-mask: var(--search);
			mask: var(--search);
		}
	}

	

	.program{
		position: relative;
		margin-bottom: 5rem;
		a{
			text-decoration: none;
			&:hover, &:focus{
				text-decoration: underline;
			}
			&:after{
				content:"";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		&-header{
			display: flex;
			@media(max-width: 767px){
				flex-direction: column;
			}
			> div{
				width: 100%;
				p{
					margin: 0 0 0.25em 0;
					line-height: 1.2;
				}
			}
			.program-area{
				color: var(--text-grey);
				text-transform: uppercase;
				font-size: 0.8rem;
				margin-top: 1.5em;
				@media(min-width: 768px){
					margin-top: 0;
					text-align: right;
				}				
			}
		}

		&-title{
			font-size: 1.5em;
		}
		&-type{
			font-size: 1.05em;
			color: var(--text-highlight);
			text-transform: uppercase;
		}
		&-description{
			margin: 2em 0;
		}
		&-bplusm{
			margin-top: 2em;
			display: flex;
			flex-direction: row;

			.combo{
				display: inline-flex;
				flex-direction: column;
				gap: 2px;
				height: 100%;
				margin-right: 0.5em;
			}
			svg{
				width: 20px;
				height: auto;
				&.top{
					fill:  var(--text-highlight)
				}
				&.bottom{
					fill: var(--header-color);
				}
			}	
		}
	}
	&.is-style-homepage {
		position: relative;
		form {
			margin: 0 auto;
		}
		.progsearchLbl {
			color: #fff;
			font-weight: 400;
		}
		.homepage-pos-intro {
			color: #fff;
			text-align: center;
			a {
				color: #fff;
				&:hover, &:focus, &:active {
					color: #fff;
				}
			}
		}
		.progsearch-wrapper {
			position: relative;
			color: var(--header-color);
			&:before {
				content: " ";
                width: 2rem;
                display: inline-block;
                height: 100%;
                background: currentColor;
                -webkit-mask: var(--search);
                mask: var(--search);
				position: absolute;
				left: 1rem;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-size: 70%;
				-webkit-mask-position: center;
				mask-repeat: no-repeat;
				mask-size: 70%;
				mask-position: center;
            }
			.progsearch {
				font-size: 1rem;
				padding: 0.75rem;
				padding-left: 3.5rem;
				color: var(--header-color);
				border: none;
				
			}
			&[data-loading]:after {
				background-image: url(/wp-includes/images/spinner-2x.gif);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 1.9rem;
				content: "";
				display: block;
				height: 100%;
				position: absolute;
				right: 1rem;
				top: 0;
				width: 2rem;
			}
		}
		.programlist {
			width: 100%;
			margin: 0 auto;
			position: absolute;
			left: 0;
			z-index: 10;
			height: 300px;
			overflow-y: auto;
			border-top: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
			@media (min-width: 768px) {
				width: 80%;
				left: 10%;
			}
			.program {
				padding: 0;
				margin-bottom: 0;		
				border-bottom: none;
				&:first-child {
					border-top: none;
				}
			}
			.no-programs {
				padding: 1rem;
				margin-bottom: 0;
				background: #fff;
				border: 1px solid #e5e5e5;
				p {
					margin: 0;
					font-size: 1rem;
					color: #000;
				}
			}
			// scroll bar styles, multiple rules for cross browser support
			scrollbar-color: var(--text-grey) #fff;
			&::-webkit-scrollbar-thumb { 
				background: var(--text-grey);
			}
			&::-webkit-scrollbar-track { 
				background: #fff;
			}
		}
		.program-header {
			p:not(.program-title) {
				display: none;
			}
			.program-title {
				margin-bottom: 0;
			}
			.program-title a {
				margin-bottom: 0;
				font-size: 1rem;
				padding: 1rem 1.5rem;
				width: 100%;
				display: block;
				color: var(--header-color);
				.degree-type-tag {
					font-size: 0.9rem;
					color: var(--text-grey);
					margin-left: 0.1rem;
				}
				&:hover, &:focus, &:active, &[aria-selected]{
					text-decoration: none;
					background: var(--text-highlight);
					color: #fff;
					text-decoration: none;
					.degree-type-tag { 
						color: #fff;
					}
				} 
			}
		}
		.program-area {
			display: none;
		}
		.wp-block-mu-programs-description {
			display: none;
		}
		.program-bplusm {
			display: none;
		}
		
	}
	.pos-announcer {
		left: -10000px;
		position: absolute;
		overflow: hidden;
	}
}