.wp-block-columns {
    &.is-style-underline-list {
        .wp-block-column{
            &> div,
            &> p,
            &> ul {
                border-bottom: 1px solid #d8d8d8;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}